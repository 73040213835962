$(document).ready(function() {
    $(document).on('click', '#big-menu #close', function() {
        $('#big-menu ul li').each(function() {
            $(this).removeClass("aos-animate");
        });

        $('#big-menu').removeClass('active');
        return false;
    });

    $(document).on('click', '#open-menu', function() {
        $('#big-menu ul li').each(function() {
            $(this).removeClass("aos-animate");
        });

        $('#big-menu').addClass('active');

        setTimeout(function() {
            $('#big-menu ul li').each(function() {
                $(this).addClass("aos-animate");
            });
        }, 200);

        return false;
    });

    $(document).on('click', '.send-newsletter', function() {
        $('#newsletter-form form').submit();
        return false;
    });

    $(document).on('click', '.activate-call-back', function() {
        $('#call-back-form').show();
        return false;
    });

    $(document).on('click', '.send-message', function() {
        $('#contact-form form').submit();
        return false;
    });

    $(document).on('click', '.send-call-back', function() {
        $('#call-back-form form').submit();
        return false;
    });

    function check_sticky_header() {
        var scrollTop = $(window).scrollTop();

        if (scrollTop > 35)
            $('header.header').addClass('sticky');
        else
            $('header.header').removeClass('sticky');
    }



    $(window).on('scroll', function() {
        check_sticky_header();
    });

    AOS.init();
});